<script>
import Layout from "../../layouts/main";
// import ReportStatus from "./wallet-chart.vue";
// import {pieChart} from "./data-apex";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';

export default {

  components: {
    Layout,
    EasyDataTable,
    // ReportStatus 
  },
  data() {
    return {
     
      searchValueTable:'',
      selectFilterSort: null,
      headers:[
            { text: "Applicant Number", value: "applicantNum" },
            { text: "Assign", value: "assignPrsn" },
            { text: "Date", value: "dateLog" },
            { text: "statusLog", value: "applicationtag" },
        ],
        items: [
            // { "applicantNum": "#9867571", "assignPrsn":"Amie Jhon", "dateLog": '10 August, 2023', timeLog: '03.00PM',  "actionBtnUrl": "/single_applicant"},
            // { "applicantNum": "#9867572", "assignPrsn":"Nick Farner", "dateLog": '15 July, 2023', timeLog: '02.00PM',  "actionBtnUrl": "/single_applicant"},
            // { "applicantNum": "#9867573", "assignPrsn":"Smith Jhon", "dateLog": '29 June, 2023', timeLog: '01.00PM', "actionBtnUrl": "/single_applicant",},
            // { "applicantNum": "#9867574", "assignPrsn":"Young Doe", "dateLog": '22 May, 2023', timeLog: '12.00AM', "actionBtnUrl": "/single_applicant",},
            // { "applicantNum": "#9867575", "assignPrsn":"Nicki Bell", "dateLog": '20 May, 2023', timeLog: '05.00PM', "actionBtnUrl": "/single_applicant",},
        ],
        arrItem:[]
    }
  },
  mounted(){
    this.fetchData();
  },
  created()
  {
    this.arrItem = this.items
  },
  methods: {
    // filterStatus(){
    //   if(this.selectFilterSort==null)
    //   {
    //     this.arrItem = this.items
    //   }
    //   else{
    //     this.arrItem = this.items.filter((item)=>{
    //    return  item.statusLog==this.selectFilterSort
    //   })
    //   }
      
    // },
    
    fetchData() {
      const t = localStorage.getItem("accessToken");
      console.log("this token is valid", t);
      let arrList = [];
      axios.get("/applications/submitted_Under/")
        .then((response) => {
          const user_data = response.data;
          console.log("this is for yes check", user_data);
          let responseList = response.data;
          responseList.forEach((item, index) => {
            let newItem = {
              sr: index + 1,
              // applicantsname: item.applicant,
              applicantNum: item.application_no,
              assignPrsn: item.rev,
              dateLog: item.created_on,
              applicationtag: item.status,
              // statusLog:item.status,
              // actionBtnUrl:"#",
            };
            // Push the transformed item to the existing array
            arrList.push(newItem);
          });
          console.log("NEW ARRAY DATa", arrList);
          this.arrItem = arrList;
        })

        .catch((error) => {
          console.error("NEW ARRAY DATa", error);
        });
    },
  }
};

</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3">
            <div class="col">
                <h2 class="font-size-22 text-theme-dark">Submitted and Under Review Applications</h2>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col"></div>
                    <div class="col-lg-6 mt-3 mt-md-0">
                        <div class="row">
                            <div class="col mt-3 mt-md-0">
                                <div class="groupSearchTop">
                                    <input type="text" v-model="searchValueTable" placeholder="Search..." class="customInputForm form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <EasyDataTable
                :headers="headers"
                :items="arrItem"
                :search-value="searchValueTable"
                border-cell
                theme-color="#df6a0d"
                :rows-per-page="10"
                buttons-pagination
                show-index
                table-class-name="table-custom-style noWrapHeading"
                    >
                <template #item-logdate="{dateLog , timeLog }">
                    <p class="mb-0 text-nowrap fw-medium">{{ dateLog}}</p>
                    <p class="mb-0 text-nowrap">{{timeLog}}</p>
                </template>
                <template #item-statusLog="{ statusLog }">
              <p
                class="badge badge-pill font-size-11"
                :class="{
                  'badge-soft-success': `${statusLog}` === 'approved',
                  'badge-soft-danger':  `${statusLog}` === 'rejected',
                  'badge-soft-warning': `${statusLog}` === 'incomplete',
                  'badge-soft-primary': `${statusLog}` === 'submitted and under review',
                }"
              >
                {{ statusLog }}
              </p>
            </template>
                <template #item-actionBtn="{actionBtnUrl}">
                    <router-link :to="`${actionBtnUrl}`" class="btn btn-sm btn-theme-dark">
                        <i class="mdi mdi-eye"></i>
                        View
                    </router-link>
                    <p class="mb-0 text-nowrap fw-medium">{{ dateLog}}</p>
                    <p class="mb-0 text-nowrap">{{timeLog}}</p>
                </template>
                <!-- <template #item-appactivity="appactivity">
                    <p class="mb-0">{{appactivity}}</p>
                </template> -->
                </EasyDataTable>
            </div>
        </div>
    </main>
  </Layout>
</template>